
<template>
  <div style="background: var(--v-component-base) !important">
    <v-row align="center" justify="end" >
      <div v-if="integrationQueues" class="ml-2">
        <v-skeleton-loader v-if="loadingIntegrationQueues"
          class="ml-5"
          width="350"
          type="chip"
        ></v-skeleton-loader>
        <v-menu offset-y v-else>
          <template v-slot:activator="{ on }">
              <v-chip v-on="on" dense class="ml-5" close-icon="refresh" close>
                <v-icon color="info">
                  format_list_numbered
                </v-icon>
                <v-chip small color="info" class="ml-2">
                  {{ integrationQueues.count }}
                </v-chip>
              </v-chip>
          </template>
          <v-list dense>
            <v-list-item v-for="(item, index) in integrationQueues.data" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ item.modelName }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip small dense color="info">{{ item.count }}</v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-spacer></v-spacer>
      <!-- <v-btn @click="getRoboDanieResubRejecData()" :disabled="roboDanieLoading">
        <span v-if="!roboDanieLoading">RoboDanie Data</span>
        <span v-if="roboDanieLoading">
          <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
        </span>
      </v-btn> -->

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="fetchFileModal = true" :disabled="loading">
            <v-icon color="info">system_update_alt</v-icon>
          </v-btn>
        </template>
        <span>Fetch File</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="openDownloadModal" :disabled="loading">
            <v-icon color="success">cloud_download</v-icon>
          </v-btn>
        </template>
        <span>Download</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="getShipments" :disabled="loading">
            <v-icon color="warning">sports_kabaddi</v-icon>
          </v-btn>
        </template>
        <span>Pull Data</span>
      </v-tooltip>
      <v-col cols="12" sm="6" md="4">

        <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="params.search" clearable placeholder="Search">
        </el-input>
      </v-col>
    </v-row>
    <v-data-table class="mt-3" dense disable-pagination fixed-header hide-default-footer :loading="loading"
      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items="shipmentData.data" :headers="headers" height="65vh"
      :calculate-widths="true" style="cursor: pointer">
      <template v-slot:[`item.action`]="{ item }">
        <v-btn-toggle borderless>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :disabled="item.shipmentId === null" icon small color="danger"
                @click="unlinkShipment(item)">
                <v-icon color="danger">link_off</v-icon>
              </v-btn>
            </template>
            <span>Unlink Shipment</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :disabled="!item.synced" icon small color="blue" @click="reSyncFile(item)">
                <v-icon color="blue">sync</v-icon>
              </v-btn>
            </template>
            <span>Sync File</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :disabled="getFileLock(item)" icon small color="secondary" @click="unlockShipment(item)">
                <v-icon color="secondary">lock_open</v-icon>
              </v-btn>
            </template>
            <span>Unlock Shipment</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon small color="secondary" @click="getContainers(item)">
                <v-icon color="secondary">launch</v-icon>
              </v-btn>
            </template>
            <span>Containers</span>
          </v-tooltip>

        </v-btn-toggle>
      </template>
      <template v-slot:[`item.shipmentId`]="{ item }">
        <v-row justify="center">
          <v-icon v-if="item.shipmentId !== null" color="green" class="mr-1">check</v-icon>
          <v-icon v-else color="red">close</v-icon>
        </v-row>
      </template>
      <template v-slot:[`item.readyToSync`]="{ item }">
        <v-row justify="center">
          <v-icon v-if="item.readyToSync" color="green" class="mr-1">check</v-icon>
          <v-icon v-else color="red">close</v-icon>
        </v-row>
      </template>
      <template v-slot:[`item.synced`]="{ item }">
        <v-row justify="center">
          <v-icon v-if="item.synced" color="green" class="mr-1">check</v-icon>
          <v-icon v-else color="red">close</v-icon>
        </v-row>
      </template>
      <template v-slot:[`item.countryFlag`]="{ item }">
        <v-avatar size="32" class="mr-1" v-if="item.countryIsoCode">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-img contain v-on="on"
                :src="`https://cdn.loglive.io/flags/4x3/${item.countryIsoCode.toLowerCase()}.svg`"></v-img>
            </template>
            <span>{{ item.portOfDischargeCity }}</span>
          </v-tooltip>
        </v-avatar>
        <span v-else>{{ item.countryName }}</span>
      </template>
    </v-data-table>
    <v-col cols="12" class="text-center">
      <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :pager-count="5"
        :page-size.sync="params.limit" :page-sizes="[18, 25, 50, 100]" :layout="!$vuetify.breakpoint.mobile
          ? 'sizes, prev, pager, next, jumper, total'
          : 'prev, pager, next'
          " :total="shipmentData.total">
      </el-pagination>
    </v-col>

    <v-dialog v-model="containerDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Containers
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon meduim @click="allJsons()">
                <v-span v-if="!loadingAllJSONs">
                  <v-icon color="#FFD700">download</v-icon>
                </v-span>
                <span v-if="loadingAllJSONs">
                  <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                </span>
              </v-btn>
            </template>
            <span>Download Shipment JSON</span>
          </v-tooltip>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-data-table class="mt-3" dense disable-pagination fixed-header hide-default-footer :loading="containerLoad"
              :items="containerData" :headers="containerHeaders" :calculate-widths="true" style="cursor: pointer">
              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon small color="blue darken-1" @click="downloadJSON(item)">
                      <v-span v-if="!item.loading">
                        <v-icon color="blue darken-1">download</v-icon>
                      </v-span>
                      <span v-if="item.loading">
                        <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                      </span>
                    </v-btn>
                  </template>
                  <span>Download Container JSON</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="fetchFileModal" width="300">
      <v-card :loading="fetchFileLoading">
        <v-container>
          <v-card-title class="headline">Fetch File Number</v-card-title>
          <v-text-field dense outlined placeholder="File Number" v-model="fileNumber"></v-text-field>
          <v-card-actions class="justify-center">
            <v-btn color="primary" text @click.native="fetchShipmentFile">Fetch File</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isDownloadModalOpen" max-width="600px">
      <v-card>
        <v-card-title>
          Download Options
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDownloadModal" color="primary">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              Robo Danie Data
              <v-spacer></v-spacer>
              <v-list-action>
                <v-btn icon @click="getRoboDanieResubRejecData()" :disabled="roboDanieLoading">
                  <span v-if="!roboDanieLoading"><v-icon>download</v-icon></span>
                  <span v-if="roboDanieLoading">
                    <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                  </span>
                </v-btn>
              </v-list-action>
            </v-list-item>

            <v-list-item>
              Load data
              <v-spacer></v-spacer>
              <v-list-action>
                <v-btn icon @click="getLoadData()" :disabled="loadDataLoading">
                  <span v-if="!loadDataLoading"><v-icon>download</v-icon></span>
                  <span v-if="loadDataLoading">
                    <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                  </span>
                </v-btn>
              </v-list-action>
            </v-list-item>

            <v-list-item>
              Phyto data
              <v-spacer></v-spacer>
              <v-list-action>
                <v-btn icon @click="getPhytoData()" :disabled="phytoDataLoading">
                  <span v-if="!phytoDataLoading"><v-icon>download</v-icon></span>
                  <span v-if="phytoDataLoading">
                    <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                  </span>
                </v-btn>
              </v-list-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { write } from 'xlsx';
import ExcelJS from 'exceljs';

export default {
  data: () => ({
    fetchFileModal: false,
    fetchFileLoading: false,
    phytoDataLoading: false,
    loadDataLoading: false,
    isDownloadModalOpen: false,
    loadingAllJSONs: false,
    searchTimer: undefined,
    page: 1,
    containerDialog: false,
    dialog: false,
    updateBadge: 0,
    sortBy: "shipperName",
    sortDesc: true,
    containerData: [],
    containerLoad: false,
    roboDanieLoading: false,
    loadingContainerPalletData: false,
    fileNumber: '',
    integrationQueues: null,
    integrationQueueCounts: [],
    loadingIntegrationQueues: false,
    showIntegrationQueues: false,
    containerHeaders: [
      {
        text: 'Action',
        value: 'action',
        allign: 'center'
      },
      {
        text: 'CTO No',
        value: 'ctoNo',
        allign: 'center'
      },
      {
        text: 'Container Number',
        value: 'containerNo',
        allign: 'center'
      }
    ],
    headers: [
      {
        text: "Action",
        value: "action",
        align: "center",
      },
      {
        text: "All Dispatches Linked",
        value: "readyToSync",
        align: "center",
      },
      {
        text: "Shipper Org ID",
        value: "shipperOrgId",
        align: "center",
      },
      {
        text: "No Of Containers",
        value: "noOfContainers",
        align: "center",
      },
      {
        text: "Shipper Name",
        value: "shipperName",
        align: "center",
      },

      {
        text: "Receiver Name",
        value: "receiverName",
        align: "center",
      },
      {
        text: "Vessel",
        value: "vessel",
        align: "center",
      },
      {
        text: "Voyage",
        value: "voyage",
        align: "right",
      },
      {
        text: "File Number",
        value: "fileNumber",
        align: "right",
      },
      {
        text:"Order No",
        value:"orderNo",
        align:"center"
      },
      {
        text: "Country of Destination",
        value: "countryFlag",
        sortable: false,
        align: "center",
        width: "250px",
      },
    ],
    params: {
      limit: 18,
      offset: 0,
      search: null,
    },
    
    saving: false,
    shipmentData: {
      total: 0,
      data: [],
    },
    loading: false,
    countries: [],
  }),
  mounted() {
    this.getShipments();
    // this.getInterationQueues();
  },
  watch: {
    async page(value) {
      this.params.offset = (value - 1) * this.params.limit;
      await this.getShipments();
    },
    "params.limit": {
      immediate: true,
      async handler() {
        this.page = 1;
        await this.getShipments();
      },
    },
    "params.search": {
      async handler() {
        this.page = 1;
        await this.getShipments();
      },
    },
  },
  methods: {
    // async getInterationQueues() {
    //   this.loadingIntegrationQueues = true
    //   this.integrationQueues = await this.$API.getInterationQueues();
    //   console.log(this.integrationQueues.data)
    //   this.loadingIntegrationQueues = false
    // },
    openDownloadModal() {
      this.isDownloadModalOpen = true;
    },
    closeDownloadModal() {
      this.isDownloadModalOpen = false;
    },
    load() {
      this.getShipments();
    },
    getFileLock(item) {
      if (item && item.shipment) { return !item.shipment.fileLocked } else { return true }
    },
    async getShipments() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.loading = true;
      this.searchTimer = setTimeout(async () => {
        let response = await this.$API.getShipmentFile({
          params: this.params,
        });
        const data = response.data;
        const items = data.map(async (item) => {
          const countryDestCode = await this.$API.getCountryByCode(item.countryOfDestination);
          const countryName = countryDestCode.name
          const countryIsoCode = countryDestCode.iso2
          return {
            ...item,
            countryName,
            countryIsoCode
          };
        });
        this.shipmentData = {
          total: response.total,
          data: await Promise.all(items),
        };
        this.loading = false;
      }, 500);
    },

    async getContainers(item) {
      this.containerLoad = true
      let fileNumber = item.fileNumber
      let res = await this.$API.getUnlinkShipmentContainers(fileNumber)
      this.containerData = res
      this.containerLoad = false
      this.containerDialog = true
    },

    async downloadJSON(item) {
      let obj = {
        shipmentId: item.shipmentId,
        containerId: item.bookingContainerId,
        shipmentDocumentTypeId: item.shipmentDocumentTypeId,
        taskId: item.taskId
      }
      try {
        item.loading = true
        // console.log(obj)
        let res = await this.$API.getShipmentContainerPalletData(obj)

        let jsonData = JSON.stringify(res)
        let blob = new Blob([jsonData], { type: "application/json" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${item.containerNo}.json`;
        a.click();
        URL.revokeObjectURL(url);
        item.loading = false
      }
      catch (err) {
        console.log('err', err)
      }
    },

    async unlinkShipment(item) {
      this.$confirm("Are you sure you want to unlink this File?", "Confirm", {
        center: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "plain",
      })
        .then(async () => {
          try {
            await this.$API.updateShipmentFileSync(item.id);
            this.$message({
              type: "success",
              message: "Shipment Successfully Unlinked!",
            });
            this.load();
          } catch (err) {
            this.$message({
              type: "error",
              message: "Error unlinking item: " + err.message,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Shipment Unlink Cancelled",
          });
        })
    },
    async getPhytoData() {
      this.phytoDataLoading = true
      let result = await this.$API.getPhytoData()
      let jsonData = JSON.stringify(result)
      let blob = new Blob([jsonData], { type: "application/json" });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = `PhytoData.json`;
      a.click();
      URL.revokeObjectURL(url);
      this.phytoDataLoading = false
    },
    async getLoadData() {
      this.loadDataLoading = true
      let result = await this.$API.getLoadData()
      console.log('result', result)

      let workbook = new ExcelJS.Workbook();
      let worksheet = workbook.addWorksheet('data1');

      worksheet.columns = [
        { header: 'FileNo', key: 'fileNumber', width: 15 },
        { header: 'Shipper Name', key: 'shipperName', width: 40 },
        { header: 'Container Number', key: 'containerNo', width: 15 },
        { header: 'Receiver Name', key: 'receiverName', width: 40 },
        { header: 'Notify Party 1', key: 'notify1', width: 40 },
        { header: 'Notify Party 2', key: 'notify2', width: 40 },
        { header: 'Courier Party', key: 'courierParty', width: 40 },
        { header: 'Vessel', key: 'vessel', width: 15 },
        { header: 'Voyage', key: 'voyage', width: 15 },
        { header: 'POL', key: 'pol', width: 15 },
        { header: 'POD', key: 'pod', width: 15 },
        { header: 'Product1', key: 'product1', width: 15 },
        { header: 'Product2', key: 'product2', width: 15 },
        { header: 'Product3', key: 'product3', width: 15 },
        { header: 'ETD', key: 'etd', width: 15 },
        { header: 'ETA', key: 'eta', width: 15 },
        { header: 'Shipping Line', key: 'shippingLine', width: 15 },

      ]

      result.forEach(item => {
        worksheet.addRow({
          fileNumber: item.shipmentFile?.fileNumber || null,
          shipperName: item.shipmentFile?.shipment?.shipperName || null,
          containerNo: item.containerNo || null,
          receiverName: item.shipmentFile?.shipment?.receiverName || null,
          notify1: item.shipmentFile?.shipment?.firstNotify?.name || null,
          notify2: item.shipmentFile?.shipment?.secondNotify?.name || null,
          courierParty: item.shipmentFile?.shipment?.courierParty?.name || null,
          vessel: item.shipmentFile?.shipment?.vesselName || null,
          voyage: item.shipmentFile?.shipment?.voyage || null,
          pol: item.shipmentFile?.shipment?.portOfLoadCity || null,
          pod: item.shipmentFile?.shipment?.finalDestinationCity || null,
          product1: item.shipmentFile?.shipment?.shipmentProducts[0]?.product?.name || null,
          product2: item.shipmentFile?.shipment?.shipmentProducts[1]?.product?.name || null,
          product3: item.shipmentFile?.shipment?.shipmentProducts[2]?.product?.name || null,
          etd: item.shipmentFile?.shipment?.etd || null,
          eta: item.shipmentFile?.shipment?.eta || null,
          shippingLine: item.shipmentFile?.shipment?.shippingLineName || null
        })
      });

      workbook.xlsx.writeBuffer()
        .then(data => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'LoadData.xlsx'; // Specify the desired file name
          a.click();
          URL.revokeObjectURL(url);
        })
        .catch(err => {
          console.error('Error writing Excel file:', err);
        });

      this.loadDataLoading = false
    },

    async getRoboDanieResubRejecData() {
      try {
        this.roboDanieLoading = true;
        let res = await this.$API.getRoboDanieResubRejecData();

        // Create a new workbook and worksheet
        let workbook = new ExcelJS.Workbook();
        let worksheet = workbook.addWorksheet('data1');

        // Configure column headers
        worksheet.columns = [
          { header: 'FileNo', key: 'fileNumber', width: 15 },
          { header: 'ID', key: 'id', width: 5 },
          { header: 'Status', key: 'status', width: 15 },
          { header: 'Start', key: 'start', width: 15 },
          { header: 'End', key: 'end', width: 15 },
          { header: 'Comments', key: 'comments', width: 40 },
          { header: 'Submitted For Inspection By', key: 'submittedForInspectionById', width: 20 },
          { header: 'Shipper Name', key: 'shipperName', width: 40 },
          { header: 'Container Number', key: 'containerNo', width: 15 },
        ];

        // Add rows to worksheet
        res.forEach(item => {
          worksheet.addRow({
            fileNumber: item.task.shipment.shipmentFile.fileNumber,
            id: item.id,
            status: item.status,
            start: item && item.start ? item.start.split('T')[0] : '',
            end: item && item.end ? item.end.split('T')[0] : '',
            comments: item.comments,
            submittedForInspectionById: item.task.submittedForInspectionById,
            shipperName: item.task.shipment.shipperName,
            containerNo: item.task && item.task.bookingContainer && item.task.bookingContainer.containerNo ? item.task.bookingContainer.containerNo : 'Breakbulk'
          });
        });

        // Save workbook to file
        workbook.xlsx.writeBuffer()
          .then(data => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'RoboDanieData.xlsx'; // Specify the desired file name
            a.click();
            URL.revokeObjectURL(url);
          })
          .catch(err => {
            console.error('Error writing Excel file:', err);
          });
        this.roboDanieLoading = false;
      } catch (err) {
        console.log(err);
      }
    },

    async allJsons() {
      try {
        this.loadingAllJSONs = true
        let data = this.containerData
        let obj = {
          data
        }
        let res = await this.$API.getShipmentContainerPalletData(obj)
        let jsonData = JSON.stringify(res)
        let blob = new Blob([jsonData], { type: "application/json" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${res.requestReference}.json`;
        a.click();
        URL.revokeObjectURL(url);

        this.loadingAllJSONs = false
      }
      catch (err) {
        console.log('err', err)
      }

    },

    async reSyncFile(item) {
      this.$confirm("Are you sure you want to resync this File?", "Confirm", {
        center: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "plain",
      })
        .then(async () => {
          try {
            await this.$API.updateShipmentReSync(item.id);
            this.$message({
              type: "success",
              message: "File Sync Reset Successfully!",
            });
            this.load();
          } catch (err) {
            this.$message({
              type: "error",
              message: "Error resync item: " + err.message,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Resync Cancelled",
          });
        })
    },
    async unlockShipment(item) {
      this.$confirm("Are you sure you want to unlock this Shipment?", "Confirm", {
        center: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "plain",
      })
        .then(async () => {
          try {
            await this.$API.unlockShipment(item.shipmentId);
            this.$message({
              type: "success",
              message: "Shipment Unlocked Successfully!",
            });
            this.load();
          } catch (err) {
            this.$message({
              type: "error",
              message: "Error resync item: " + err.message,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Shipment Unlock Cancelled",
          });
        })
    },
    close() {
      this.containerDialog = false
    },

    async fetchShipmentFile() {
      try {
        if (this.fileNumber != '') {
          this.fetchFileLoading = true
          await this.$API.fetchShipmentFile(this.fileNumber)
          this.fileNumber = ''
          this.fetchFileLoading = false
          this.fetchFileModal = false
          this.$message({
            type: "success",
            message: "File Fetched Successfully!",
          });
        } else {
          this.$message({
            type: "error",
            message: "File Number is required.",
          });
        }

      } catch (e) {
        this.$message({
          type: "error",
          message: "File Fetch Internal Error.",
        });
        console.log('File Fetch Error', e);
        this.fetchFileLoading = false
      }
    }
  },
};
</script>

<style scoped></style>